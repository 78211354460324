import { NgModule } from '@angular/core';
import { provideRouter } from '@angular/router';
import { StatisticsComponent } from './statistics.component';

@NgModule({
  imports: [StatisticsComponent],
  providers: [
    provideRouter([
      {
        path: '',
        component: StatisticsComponent,
      },
    ]),
  ],
})
export class StatisticsModule {}
